import { Box, Heading, Accordion } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Container } from "../components/container"
import { Layout } from "../components/layout"
import { Title } from "../components/title"
import { AccordionItem } from "../components/accordionItem"
import { SEO } from "../components/seo"

const Ofm: FunctionComponent<{ data: any }> = ({ data }) => {
  const sections = data.prismicOfm.data.body

  return (
    <Layout>
      <Box
        position="fixed"
        h="100vh"
        w="100vw"
        bg="#fff"
        zIndex={-1}
        opacity={0.35}
      />
      <Container>
        <SEO title="#ŐFM" />
        <Title text="#ŐFM" />

        <Accordion allowToggle>
          {sections?.map((data: any, index: number) => {
            const { id, primary } = data
            return (
              <AccordionItem
                key={id}
                title={primary.title?.text}
                content={primary.content.html}
              />
            )
          })}
        </Accordion>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query OFM {
    prismicOfm {
      data {
        body {
          ... on PrismicOfmBodySection {
            id
            primary {
              content {
                html
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default Ofm
